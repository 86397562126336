import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { SkillReducer } from '../redux/skill/skill-reducer'
import { CityReducer } from '../redux/city/city-reducer'
import { StylistsReducer } from '../redux/stylist/stylist-reducer'
import { SalonReducer } from '../redux/salon/salon-reducer'
import { orderReducer } from '../redux/order/order-reducer'
import { CustomersReducer } from '../redux/customer/customer-reducer'
import { CommentReducer } from '../redux/comment/comment-reducer'

const rootReducer = combineReducers({
    skill: SkillReducer,
    city: CityReducer,
    stylist: StylistsReducer,
    salon: SalonReducer,
    order: orderReducer,
    customer: CustomersReducer,
    comment: CommentReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store