import { GET_CITY } from './city-action'

const initialState = {
    city: []
}

export const CityReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CITY:
            return {
                ...state,
                city: action.payload
            }
    
        default:
            return state
    }
}