import axios from '../../config/axios'

export const GET_CUSTOMERS = "GET_CUSTOMERS"

export function getCustomers(page, mobile, fullName) {
    return async dispatch => {
        try {
            
            var filter = ``
            if(mobile != "") {
                filter = filter + `&mobile=${mobile}`
            }
            if(fullName != "") {
                filter = filter + `&fullName=${fullName}`
            }

            const response = await axios.get(`/admin/customers?page=${page}${filter}`)
            dispatch({
                type: GET_CUSTOMERS,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}