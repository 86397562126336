import { GET_COMMENTS } from './comment-action'

const initialState = {
    comments: []
}

export const CommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
    
        default:
            return state
    }
}