import { GET_SALONS, GET_SALONS_IDENTIFICATION } from './salon-action'

const initialState = {
    salons: [],
    salonsIdentification: []
}

export const SalonReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALONS:
            return {
                ...state,
                salons: action.payload
            }
        case GET_SALONS_IDENTIFICATION:
            return {
                ...state,
                salonsIdentification: action.payload
            }
    
        default:
            return state
    }
}