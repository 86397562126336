import { GET_STYLISTS, GET_STYLIST_IDENTIFICATION } from './stylist-action'

const initialState = {
    stylists: [],
    authentication: [],
}

export const StylistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STYLISTS:
            return {
                ...state,
                stylists: action.payload
            }
        case GET_STYLIST_IDENTIFICATION:
            return {
                ...state,
                authentication: action.payload
            }
    
        default:
            return state
    }
}