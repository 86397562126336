import axios from "axios"
import { API_URL } from './const'
const baseURL = API_URL;

const axiosInstance = axios.create({
    baseURL
});

axiosInstance.interceptors.request.use(async function (config) {
    const token = localStorage.getItem('token5252781500')
    
    config.headers.Authorization = `bearer ${token}`
    config.headers.responseType = "application/json"
    config.headers["Accept-Language"] = "fa";
    //config.headers["Cache-Control"] = "no-cache"        
    return config;
});

export default axiosInstance;
