import axios from '../../config/axios'

export const GET_SALONS = "GET_SALONS"
export const GET_SALONS_IDENTIFICATION = "GET_SALONS_IDENTIFICATION"

export function getSalonsAuthentication(page= 1) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/salon/authentication?page=${page}`)
            dispatch({
                type: GET_SALONS_IDENTIFICATION,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function verifyAuthentication(id) {
    return async dispatch => {
        try {
            const response = await axios.put(`/admin/salon/verify/authentication/${id}`)
            return response
        } catch (error) {
            return error
        }
    }
}

export function getSalons(page) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/salon?page=${page}`)
            dispatch({
                type: GET_SALONS,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function updateShowSalon(salon_id , show) {
    return async dispatch => {
        try {
            const response = await axios.put(`/admin/salon/show/${salon_id}/${show}`)
            return response
        } catch (error) {
            return error
        }
    }
}