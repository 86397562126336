import axios from '../../config/axios'

export const GET_STYLISTS = "GET_STYLISTS"
export const GET_STYLIST_IDENTIFICATION = "GET_STYLIST_IDENTIFICATION"

export function getStylist(page) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/stylist?page=${page}`)
            dispatch({
                type: GET_STYLISTS,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function getStylistsAuthentication(page= 1) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/stylist/authentication?page=${page}`)
            dispatch({
                type: GET_STYLIST_IDENTIFICATION,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function verifyAuthentication(id) {
    return async dispatch => {
        try {
            const response = await axios.put(`/admin/stylist/verify/authentication/${id}`)
            return response
        } catch (error) {
            return error
        }
    }
}

export function updateShowStylist(stylist_id, show) {
    return async dispatch => {
        try {
            const response = await axios.put(`/admin/stylist/show/${stylist_id}/${show}`)
            return response
        } catch (error) {
            return error
        }
    }
}