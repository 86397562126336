import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd'
import { DashboardOutlined, PartitionOutlined, SettingOutlined, UsergroupAddOutlined, ShopOutlined, ShoppingCartOutlined, UserOutlined, CommentOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import logo from '../assets/images/beautybook.svg'

export default function SideBar(props) {

    const { SubMenu } = Menu
    const [visible, setVisible] = useState(false)
    const [first, setFirst] = useState(0)
    const { Sider } = Layout
    let history = useHistory()
    const path = history.location.pathname.split('/')

    useEffect(() => {

        if (first === 0) {
            setFirst(1)
        } else {
            setVisible(!visible)
        }

    }, [props.state])

    return (

        <Sider
            style={{ minHeight: "100vh" }}
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            collapsible
            collapsed={visible}
            theme="light"
            onBreakpoint={broken => {
                if (broken === false) {
                    setVisible(false)
                }
                else {
                    setVisible(true)
                }
            }}
            onCollapse={(collapsed, type) => {
                //alert("collapse")
                //console.log(collapsed, type);
            }}
        >
            <div className="logo">
                <img alt="" src={logo} />
            </div>
            <Menu theme="light" mode="inline" defaultSelectedKeys={path[2]}>

                <Menu.Item onClick={() => history.push(`/manager/list`)} icon={<DashboardOutlined style={{ marginLeft: 10, marginRight: 20}} />} key="dashboard">داشبورد</Menu.Item>

                <SubMenu key="sub1" icon={<UsergroupAddOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="آرایشگران">
                    <Menu.Item onClick={() => history.push(`/manager/stylist/list`)} key="bank-quiz">لیست آرایشگرها</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/stylist/identification`)} key="identification-stylist">مدارک ارسال شده</Menu.Item>
                </SubMenu>
                
                <SubMenu key="sub2" icon={<UserOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="مشتری">
                    <Menu.Item onClick={() => history.push(`/manager/customer/list`)} key="customers-list">لیست مشتریان</Menu.Item>
                </SubMenu>

                <SubMenu key="salons" icon={<ShopOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="سالن ها">
                    <Menu.Item onClick={() => history.push(`/manager/salon/list`)} key="salon-list">لیست سالن ها</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/salon/identification`)} key="identification-list">مدارک ارسال شده</Menu.Item>
                </SubMenu>

                <SubMenu key="orders" icon={<ShoppingCartOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="سفارش ها">
                    <Menu.Item onClick={() => history.push(`/manager/order/list`)} key="order-list">لیست سفارشات</Menu.Item>
                </SubMenu>

                <SubMenu key="comments" icon={<CommentOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="نظرات">
                    <Menu.Item onClick={() => history.push(`/manager/comment/list`)} key="comments-list">نظرات در انتظار تایید</Menu.Item>
                </SubMenu>

                <SubMenu key="skill" icon={<SettingOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="مهارت">
                    <Menu.Item onClick={() => history.push(`/manager/skill/list`)} key="list">لیست مهارت ها</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/skill/create`)} key="create">افزودن مهارت</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/skill/create-sub`)} key="create-sub">افزودن زیر مهارت</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/subskill/list`)} key="sub-list">لیست زیر مهارت ها</Menu.Item>
                </SubMenu>

                <SubMenu key="city" icon={<PartitionOutlined style={{ marginRight: 10, marginLeft: 10 }} />} title="مدیریت شهرها">
                    <Menu.Item onClick={() => history.push(`/manager/city/list`)} key="city-list">لیست شهرها</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/city/state`)} key="state-create">افزودن استان جدید</Menu.Item>
                    <Menu.Item onClick={() => history.push(`/manager/city/create`)} key="city-create">افزودن شهر جدید</Menu.Item>
                </SubMenu>



            </Menu>
        </Sider>

    )
}
