import { GET_ORDERS } from './order-action'

const initialState = {
    orders: []
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
    
        default:
            return state
    }
}