import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom';

export default function Routes() {

    const Login = lazy(() => import('./auth/Login'))

    return (
        <Switch>
            <Suspense fallback={<div>Loading</div>}>
                <Route exact path="*" component={Login} />
            </Suspense>
        </Switch>

    )
}
