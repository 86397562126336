import { GET_CUSTOMERS } from './customer-action'

const initialState = {
    customers: []
}

export const CustomersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            }
    
        default:
            return state
    }
}