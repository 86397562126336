import { GET_SKILL, GET_SKILL_ID, GET_SUB_SKILL, GET_SUB_SKILLS } from './skill-action'

const initialState = {
    skills: [],
    skill: [],
    subSkills: [],
    subSkill: [],
}

export const SkillReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SKILL:
            return {
                ...state,
                skills: action.payload
            }
        case GET_SKILL_ID:
            return {
                ...state,
                skill: action.payload
            }
        case GET_SUB_SKILL:
            return {
                ...state,
                subSkill: action.payload
            }
        case GET_SUB_SKILLS:
            return {
                ...state,
                subSkills: action.payload
            }
    
        default:
            return state
    }
}