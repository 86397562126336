import axios from '../../config/axios'

export const GET_CITY = "GET_CITY"

export function getCity() {
    return async dispatch => {
        try {
            const response = await axios.get('/admin/city')
            dispatch({
                type: GET_CITY,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function createState(data) {
    return async dispatch => {
        try {
            const response = await axios.post('/admin/state' , data)
            return response
        } catch (error) {
            return error
        }
    }
}

export function createCity(data) {
    return async dispatch => {
        try {
            const response = await axios.post('/admin/city' , data)
            return response
        } catch (error) {
            return error
        }
    }
}