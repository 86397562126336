import axios from '../../config/axios'

export const GET_COMMENTS = "GET_COMMENTS"

export function getComments() {
    return async dispatch => {
        try {
            const response = await axios.get('/admin/comments')
            dispatch({
                type: GET_COMMENTS,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function updateStatus(data) {
    return async dispatch => {
        try {
            const response = await axios.put('/admin/comments/status', data)
            return response
        } catch (error) {
            return error
        }
    }
}