import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom';

export default function AppRoutes() {

    const Dashboard = lazy(() => import('./dashboard/index'))

    const SkillCreate = lazy(() => import('./skill/SkillCreate'))
    const SkillEdit = lazy(() => import('./skill/SkillEdit'))
    const SubSkillCreate = lazy(() => import('./skill/SubSkillCreate'))
    const ListSkill = lazy(() => import('./skill/ListSkill'))
    const ListSubSkills = lazy(() => import('./skill/ListSubSkills'))
    const UpdateSubSkills = lazy(() => import('./skill/UpdateSubSkills'))

    const CityList = lazy(() => import('./city/ListCity'))
    const CityCreate = lazy(() => import('./city/CityCreate'))
    const StateCreate = lazy(() => import('./city/StateCreate'))

    const StylistList = lazy(() => import('./stylist/ListStylist'))
    const AuthenticationStylist = lazy(() => import('./stylist/Authentication'))

    const SalonList = lazy(() => import('./salons/ListSalons'))
    const AuthenticationList = lazy(() => import('./salons/Authentication'))

    const OrdersList = lazy(() => import('./orders/List'))

    const CommentListWaiting = lazy(() => import('./comment/ListWaiting'))

    const CustomersList = lazy(() => import('./customer/CustomersList'))
    

    return (
        <Switch>
            <Suspense fallback={<div> درحال بارگذاری... </div>}>

                <Route exact path="/" component={Dashboard} />

                <Route exact path="/manager/skill/create" component={SkillCreate} />
                <Route exact path="/manager/skill/edit/:id" component={SkillEdit} />
                <Route exact path="/manager/skill/create-sub" component={SubSkillCreate} />
                <Route exact path="/manager/skill/list" component={ListSkill} />
                <Route exact path="/manager/subskill/list" component={ListSubSkills} />
                <Route exact path="/manager/subskill/edit/:id" component={UpdateSubSkills} />

                <Route exact path="/manager/city/list" component={CityList} />
                <Route exact path="/manager/city/state" component={StateCreate} />
                <Route exact path="/manager/city/create" component={CityCreate} />

                <Route exact path="/manager/stylist/list" component={StylistList} />
                <Route exact path="/manager/stylist/identification" component={AuthenticationStylist} />
                
                <Route exact path="/manager/salon/list" component={SalonList} />
                <Route exact path="/manager/salon/identification" component={AuthenticationList} />

                <Route exact path="/manager/order/list" component={OrdersList} />

                <Route exact path="/manager/customer/list" component={CustomersList} />

                <Route exact path="/manager/comment/list" component={CommentListWaiting} />

            </Suspense>
        </Switch>

    )
}