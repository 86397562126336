import { Provider } from 'react-redux'
import RootStore from './config/Store'
import { BrowserRouter } from 'react-router-dom'
import Main from './Pages/Main'

function App() {

  return (
    <Provider store={RootStore}>
      <BrowserRouter basename="/">
        <Main />
      </BrowserRouter>
    </Provider>
  );


}

export default App;
