import axios from '../../config/axios'

export const GET_SKILL = "GET_SKILL"
export const GET_SKILL_ID = "GET_SKILL_ID"
export const GET_SUB_SKILLS = "GET_SUB_SKILLS"
export const GET_SUB_SKILL = "GET_SUB_SKILL"

export function getSubSkills() {
    return async dispatch => {
        try {
            const response = await axios.get('/admin/subskills')
            dispatch({
                type: GET_SUB_SKILLS,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function getSubSkillById(id) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/subskills/${id}`)
            dispatch({
                type: GET_SUB_SKILL,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function updateSubSkill(id , data) {
    return async dispatch => {
        try {
            const response = await axios.put(`/admin/subskills/${id}` , data)
            return response
        } catch (error) {
            return error
        }
    }
}

export function deleteSubSkill(id) {
    return async dispatch => {
        try {
            const response = await axios.delete(`/admin/subskills/${id}`)
            return response
        } catch (error) {
            return error
        }
    }
}

export function getSkillById(id) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/skill/${id}`)
            dispatch({
                type: GET_SKILL_ID,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}

export function updateSkill(id , data) {
    return async dispatch => {
        try {
            const response = await axios.put(`/admin/skill/${id}` , data)
            return response
        } catch (error) {
            return error
        }
    }
}

export function createSkill(data) {
    return async dispatch => {
        try {
            const response = await axios.post('/admin/skill' , data)
            return response
        } catch (error) {
            return error
        }
    }
}

export function createSubSkill(data , id) {
    return async dispatch => {
        try {
            const response = await axios.post(`/admin/skill/${id}` , data)
            return response
        } catch (error) {
            return error
        }
    }
}

export function getSkill() {
    return async dispatch => {
        try {
            const response = await axios.get('/admin/skill')
            dispatch({
                type: GET_SKILL,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}