import React, { useState, useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import { Layout } from 'antd'
import SideBar from '../shared/SideBar'
import RouteLogin from './Route'
import AppRoute from './AppRoute'
import axios from '../config/axios'
import { API_URL } from '../config/const'
import { MenuOutlined, LogoutOutlined } from '@ant-design/icons'

function Main() {

    const { Header, Content } = Layout
    const [state, setState] = useState("loading")
    const [triger, setTriger] = useState(false)
    let history = useHistory()
    
    useEffect(() => {

        if (history.location.pathname === "/login") {
            setState("pageLogin")
        }
        else if (!localStorage.getItem('token5252781500')) {

            setState("login")
        }
        else {
            axios.get(`${API_URL}/admin/checkToken`).then((res) => {
                setState("next")
            }).catch((err) => {
                setState("login")
            })
        }

    }, [history])


    const logOut = () => {
        localStorage.removeItem("beautybook_admin")
        window.location.href = '/login'
    }

    if (state === "loading") {

        return (
            <div>در حال بارگذاری</div>
        )

    }
    else if (state === "login") {
        window.location.href = "/login"
    }
    else if (state === "pageLogin") {
        return (
            <RouteLogin />
        )
    }   

    else if (state === "next") {
        return (
            <Layout >
                <SideBar state={triger} />
                <Layout>
                    <Header className="site-layout-sub-header-background" style={{ padding: 0, display:"flex", background: '#2D3063' }}>
                        <div><MenuOutlined onClick={() => setTriger(!triger)} style={{color:"white", marginRight:15, fontSize:18, cursor:"pointer"}}  /></div>
                        <div onClick={logOut} style={{position:"absolute", left:10}}>
                            <LogoutOutlined  style={{ color: 'white',marginRight:15, fontSize:18, cursor:"pointer", textAlign:"left"}} />
                            
                        </div>
                    </Header>
                    <Content style={{ margin: '24px 16px 0' }}>

                        <AppRoute />
                    </Content>

                </Layout>
            </Layout>

        )
    }



} export default withRouter(Main)