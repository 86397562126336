import axios from '../../config/axios'

export const GET_ORDERS = "GET_ORDERS"

export function getOrders(page, status) {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/order?page=${page}&status=${status}`)
            dispatch({
                type: GET_ORDERS,
                payload: response.data.data
            })
        } catch (error) {
            
        }
    }
}